import React, { Fragment } from "react"
import { Link } from "gatsby"

const pages = [
  { link: "/", name: "main" },
  { link: "/projects", name: "projects" },
  { link: "/ideas", name: "ideas" },
  { link: "/links", name: "links" },
  { link: "/blog", name: "blog" },
]

const Header = ({ location }) => {
  const locationRoot = location.split("/").filter(a => !!a)[0] ?? ""
  return (
    <header>
      <nav id="menu">
        <ul>
          {pages.map(({ name, link }) => (
            <Fragment key={link}>
              <li>
                <Link
                  to={link}
                  className={locationRoot === link.slice(1) ? "active" : ""}
                >
                  {name}
                </Link>
              </li>{" "}
            </Fragment>
          ))}
        </ul>
      </nav>
    </header>
  )
}

export default Header
