import React from "react"
// import PropTypes from "prop-types"
import { useLocation } from "@reach/router"
import { graphql, useStaticQuery } from "gatsby"
import { Helmet } from "react-helmet"
const SEO = () => {
  const {
    allMdx: { edges },
  } = useStaticQuery(query)
  const { pathname: location } = useLocation()

  let snippets = location.split("/").filter(a => !!a)

  if (snippets[0] === "blog") {
    const posts = edges.map(({ node }) => ({
      ...node.frontmatter,
      url: node.slug,
    }))
    const post = posts.find(({ url }) => url === snippets[1])

    if (post) {
      snippets = ["blog", post.title]
    }
  }

  const title = [...snippets.reverse(), "mrkvon"].join(" :: ")
  const description =
    "one more human has a homepage: freedom, sharing, coding, music"

  return (
    <Helmet title={title}>
      <meta name="description" content="mrkvon homepage" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:url" content="https://mrkvon.org" />
      <meta property="og:description" content={description} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <link
        rel="meta"
        type="text/turtle"
        title="FOAF"
        href="https://id.mrkvon.org"
      />
      {/*
      <meta name="image" content={seo.image} />
      {seo.url && <meta property="og:url" content={seo.url} />}
      {(article ? true : null) && <meta property="og:type" content="article" />}
      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}
      {seo.image && <meta property="og:image" content={seo.image} />}
      <meta name="twitter:card" content="summary_large_image" />
      {twitterUsername && (
        <meta name="twitter:creator" content={twitterUsername} />
      )}
      {seo.title && <meta name="twitter:title" content={seo.title} />}
      {seo.description && (
        <meta name="twitter:description" content={seo.description} />
      )}
      {seo.image && <meta name="twitter:image" content={seo.image} />*/}
    </Helmet>
  )
}
export default SEO

const query = graphql`
  query SEOQuery {
    site {
      siteMetadata {
        url
      }
    }
    allMdx {
      edges {
        node {
          slug
          frontmatter {
            title
          }
        }
      }
    }
  }
`
